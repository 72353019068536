<template>
  <div>
    <div class="service">

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="预约" name="0">
          <div slot="label" class="tabs_label" >
            预约
            <el-badge :hidden="appoint_num == 0" :value="appoint_num" size="mini" class="item"></el-badge>
          </div>
        </el-tab-pane>
        <el-tab-pane label="预约服务记录" name="1">

        </el-tab-pane>
        <el-tab-pane label="随访" name="2">
          <div slot="label" class="tabs_label" >
            随访
            <el-badge :hidden="follow_num == 0" :value="follow_num" size="mini" class="item"></el-badge>
          </div>
        </el-tab-pane>
        <el-tab-pane label="随访服务记录" name="3">

        </el-tab-pane>
      </el-tabs>

    </div>

    <template v-if="activeName == 0 || activeName == 1 ">
      <ul class="card_list">
        <template v-if="list.length != 0">
          <li v-for="(item,index) in list" :key="item.serviceId"
              :style="{ marginRight : ( ((index+1)%4) ? '6%':'0' ) } "
              @click="linkDeta(item)">
            <div class="item">
              <el-badge style="display: flex" :is-dot="!item.isRead"  >
                <div class="left">
                  <el-tooltip effect="dark" :content="item.name" placement="top">
                    <p class="name">{{ item.name }}</p>
                  </el-tooltip>
                  <p v-if="item.gender == 0">女</p>
                  <p v-if="item.gender == 1">男</p>
                </div>
                <div class="left">
                  <p>{{ $store.state.category.filter((items) => { return items.dictKey == item.productCategory })[0].dictValue }}</p>
                  <p>{{ item.phoneNo }}</p>
                </div>

              </el-badge>
            </div>
            <div class="footer">
              <p>{{item.appointDate}}</p>
              <p v-if="activeName == 0">未确认预约</p>
              <p v-if="activeName == 1">已确认预约</p>
            </div>
          </li>

          <div class="pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :page-sizes="[9, 27, 45, 81]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </template>

        <template v-else>
          <NoList>
            <template #text>
              <p>暂无数据</p>
            </template>
          </NoList>
        </template>

      </ul>
    </template>

    <template v-if="activeName == 2 || activeName == 3 ">
      <ul class="card_list">
        <template v-if="list.length != 0">
          <li v-for="(item,index) in list" :key="item.serviceId"
              :style="{ marginRight : ( ((index+1)%4) ? '6%':'0' ) } "
              @click="linkDeta(item)">
            <div class="item">
              <el-badge style="display: flex" :is-dot="!item.isRead"  >
                <div class="left">
                  <el-tooltip effect="dark" :content="item.name" placement="top">
                    <p class="name">{{ item.name }}</p>
                  </el-tooltip>
                  <p v-if="item.gender == 0">女</p>
                  <p v-if="item.gender == 1">男</p>
                </div>
                <div class="left">
                  <p>{{ $store.state.reportType.filter((items) => { return items.dictKey == item.reportType })[0].dictValue }}</p>
                  <p>{{ item.phoneNo }}</p>
                </div>
              </el-badge>
            </div>
            <div class="footer">
              <p v-if="item.callCount == 0">未呼叫</p>
<!--              <p v-if="activeName == 2">呼叫1次</p>-->
              <p v-else>呼叫{{ item.callCount }}次</p>
            </div>
          </li>

          <div class="pagination">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.pageNum"
                :page-sizes="[8, 16, 40, 64]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
          </div>
        </template>

        <template v-else>
          <NoList>
            <template #text>
              <p>暂无数据</p>
            </template>
          </NoList>
        </template>

      </ul>
    </template>

  </div>
</template>

<script>
export default {
  name: "Service",
  data() {
    return{
      activeName: "0",
      list: [],
      page: {
        pageNum: 1,
        pageSize: 8,
      },
      total: 0,
      appoint_num: 0,
      follow_num: 0
    }
  },
  created() {

    if(this.$route.query.activeName != null) {
      this.activeName= this.$route.query.activeName;
    }

    // 未读消息
    this.get_unread_message();
    // 获取列表
    this.get_list(this.page);
  },
  mounted() {

  },
  methods: {
    get_list(y) {
      if(this.activeName == 0) {
        this.$get("cs/appoint",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }else if(this.activeName == 1) {
        this.$get("cs/appoint/history",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }else if(this.activeName == 2) {
        y["status"]= "0";
        this.$get("cs/follow",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }else if(this.activeName == 3) {
        y["status"]= "1";
        this.$get("cs/follow",y)
            .then(res => {
              if(res) {
                console.log(res);
                this.list= res.data.list;
                this.total= res.data.total;
              }
            })
      }
    },
    // 未读消息
    get_unread_message() {
      this.$get("cs/message")
        .then(res => {
          if(res) {
            res.data.forEach(item => {
              if(item.type == '0') {
                this.appoint_num = item.unreadCount;
              } else {
                this.follow_num = item.unreadCount;
              }
            })
          }
        })
    },
    handleCurrentChange(e) {
      console.log(`第${e}页`);
      this.page.pageNum= e;
      this.get_list(this.page);
    },
    handleSizeChange(e) {
      console.log(`每页${e}条`);
      this.page.pageNum= 1;
      this.page.pageSize= e;
      this.get_list(this.page);
    },
    linkDeta(e) {
      if(this.activeName === '0' || this.activeName === '1') {
        this.$router.push({
          path: "/ServiceDeta",
          query: {
            id: e.serviceId,
            orderNo: e.orderNo,
            state: this.activeName
          }
        })
      }else {
        this.$router.push({
          path: "/CellDeta",
          query: {
            id: e.serviceId,
            state: this.activeName
          }
        })
      }

    },
    handleClick() {
      this.page.pageNum = 1;
      this.page.pageSize = 8;
      this.list= [];

      this.get_list(this.page);
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../style/Service/Service";
</style>
